<template>
  <div class="info-icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 8H12.01" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11 12H12V16H13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <InfoMessage :arrowDir="arrowDir" :top="top" :right="right" :bottom="bottom" :left="left">
      <slot></slot>
    </InfoMessage>
  </div>
</template>

<script>
import InfoMessage from "@/components/parts/info-message.vue";

export default {
  components: {
    InfoMessage
  },
  props: {
    arrowDir: {
      type: String,
      default: "bc",
      validator(value) {
        return ["lt", "lc", "lb", "rt", "rc", "rb", "tl", "tc", "tr", "bl", "bc", "br"].includes(value);
      }
    },
    top: {
      type: String
    },
    left: {
      type: String
    },
    right: {
      type: String
    },
    bottom: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.info-icon {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 6px;

  @media (max-width: 359px) {
    margin-right: -9px;
  }
  svg {
    color: var(--blue-main);
    width: 16px;
    height: 16px;
  }
  &:hover .info-meassage {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
  }
}
</style>
