<template>
  <div class="custom-select" :key="selectKey" :class="{ open: open }" :tabindex="tabindex" @blur="selectBlur">
    <div class="selected" :class="{ active: statusComp }" @click="selectShow">
      {{ selectedComp }}
      <span v-if="to" class="red-text">&nbsp;{{ to }}</span>
      <template v-if="selectType == 'limit'"> ₴ в день</template>
      <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L4.5 5L8 1" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div class="items-inner">
        <div v-if="cardEmpty == true" @click="resetSelect">
          <span class="option-value">{{ cardEmptyText }}</span>
        </div>
        <div v-for="(option, i) of options" :key="i" @click="selectClick(option, i)" :class="{ active: i == activeFlag }">
          <span class="option-value">
            {{ option.label || cardFormat(option.card) || (option.sum ?? 0) }}
            <img v-if="option.card && option.card[0] == 4" src="@/assets/img/visa_logo.svg" class="card-img" alt="Card" />
            <img v-else-if="option.card && option.card[0] == 5" src="@/assets/img/mc_logo.svg" class="card-img" alt="Card" />
            <span v-if="option.to" class="red-text">&nbsp;{{ option.to }}</span>
            <template v-if="selectType == 'limit'"> ₴ в день</template>
          </span>
          <span v-if="option.description" class="option-description">{{ option.description }}</span>
        </div>
      </div>
    </div>
    <span v-if="label" class="label m-text-food-title">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    valid: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      required: true
    },
    activeOption: {
      type: String,
      required: false,
      default: null
    },
    selectType: {
      type: String
    },
    default: {
      type: String,
      required: false,
      default: null
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    cardEmpty: {
      type: Boolean,
      default: false
    },
    cardEmptyText: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectKey: 1,
      open: false,
      activeId: null,
      selected: null,
      activeFlag: null,
      to: null
    };
  },
  computed: {
    selectedComp() {
      return this.selectedChange();
    },
    statusComp() {
      return !this.default && this.selected === null ? false : true;
    }
  },
  methods: {
    resetSelect() {
      (this.open = false), (this.activeId = null), (this.selected = null), (this.activeFlag = null);
      this.$emit("selectPressed", "");
    },
    selectedChange() {
      if (this.default && !this.selected) {
        this.selected = this.default;
      } else if (!this.default && this.selected === null) {
        this.selected = null;
      }

      return this.selected;
    },
    selectShow(event) {
      this.open = !this.open;

      this.activeFlag = this.activeOption && this.activeId === null ? this.activeOption - 1 : this.activeId;

      const item = event.target.closest(".custom-select");

      if (this.open) {
        const height = item.querySelector(".items-inner").offsetHeight + 8;
        item.querySelector(".items").style.height = height + "px";
      } else {
        item.querySelector(".items").style.height = null;
      }
    },
    selectBlur(event) {
      this.open = false;

      const item = event.target.closest(".custom-select");

      if (this.open) {
        const height = item.querySelector(".items-inner").offsetHeight + 8;
        item.querySelector(".items").style.height = height + "px";
      } else {
        item.querySelector(".items").style.height = null;
      }
    },
    selectClick(option, i) {
      this.selected = option.label || this.cardFormat(option.card) || "" || (option.sum ?? 0);
      this.to = option.to;
      this.open = false;
      this.active = true;
      this.$emit("input", option);
      this.$emit("selectPressed", option);
      this.activeId = i;
      this.activeFlag = this.activeId;
      const selectArr = document.querySelectorAll(".custom-select");
      selectArr.forEach(function(item) {
        item.querySelector(".items").style.height = null;
      });
    },
    cardFormat(card) {
      if (card) {
        let format = card.split("");
        for (let i = 0; i < format.length - 4; i++) {
          format[i] = "•";
        }
        format.splice(7, 0, " ");
        let newFormat = format.join("").substr(3);
        return newFormat;
      } else {
        return "";
      }
    }
  },
  mounted() {
    this.$emit("input", this.selected);
  }
};
</script>

<style lang="scss" scoped>
.custom {
  &-select {
    margin-bottom: 39px;
    position: relative;
    width: calc(50% - 20px);
    font-size: 14px;
    text-align: left;
    outline: none;
    height: 47px;

    @media (max-width: 764px) {
      width: 100%;
    }

    .red-text {
      color: var(--error);
    }

    .selected {
      height: 49px;
      color: var(--dark-40);
      border-radius: 6px;
      border: 1px solid var(--dark-10);
      border-bottom: 1px solid var(--dark-10);
      background: var(--bg-white);
      padding: 0 35px 0 15px;
      line-height: 49px;
      cursor: pointer;
      user-select: none;
      letter-spacing: 0.03em;
      transition: height 0.5s ease, border-color 0.5s ease;
      @media (max-width: 767px) {
        font-size: 16px;
      }

      &.active {
        color: initial;
      }

      svg {
        position: absolute;
        top: 22px;
        right: 1em;
        transition: 0.5s ease;
      }
    }

    &.open {
      .selected {
        border-color: var(--blue-main);
        border-radius: 6px 6px 0 0;

        svg {
          transform: rotate(-180deg);
        }
      }

      .label {
        color: var(--blue-main);
      }
    }

    .items {
      border-radius: 0 0 6px 6px;
      overflow: hidden;
      border-left: 1px solid var(--blue-main);
      border-right: 1px solid var(--blue-main);
      border-bottom: 1px solid var(--blue-main);
      background: var(--bg-white);
      position: absolute;
      top: calc(100% - 2px);
      left: 0;
      right: 0;
      height: 0;
      z-index: 1;
      transition: 200ms ease;

      &-inner {
        max-height: 140px;
        overflow-y: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      &-inner div {
        margin-bottom: 14px;
        padding-left: 37px;
        cursor: pointer;
        user-select: none;
        position: relative;

        &:hover .option-value {
          color: var(--blue-main);
        }

        &.active {
          .option-value {
            color: var(--blue-main);
          }

          &:before {
            content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.04319 12L13.6014 4M5.98511 11.9997L2.40137 8.17569' stroke='%233CAFE9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            position: absolute;
            left: 15px;
            top: -1px;
          }
        }
      }

      &:not(.selectHide) {
        border-color: var(--blue-main);
        opacity: 1;
      }
    }

    &.not-valid {
      .selected {
        border-color: var(--error);
      }

      svg path {
        stroke: var(--error);
      }

      .label {
        color: var(--error);
      }
    }
  }
}

.selectHide {
  opacity: 0;
}

.label {
  top: 50%;
  left: 11px;
  transform: translateY(-50%);
  position: absolute;
  color: var(--dark-40);
  padding: 0 4px;
  background: var(--white-100);
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.selected.active ~ .label {
  font-size: 12px;
  top: -11px;
  transform: none;
}

.option-description {
  display: block;
  line-height: 24px;
  font-size: 12px;
  color: var(--dark-40);
}
.option-value {
  display: flex;
  align-items: center;
}
.card-img {
  margin-left: 4px;
  width: 22px;
}
</style>
