<template>
  <textarea :placeholder="placeholder" @input="$emit('update:value', $event.target.value)" class="m-text-des-n"></textarea>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String
    },
    value: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: 14px 16px;
  color: var(--dark-100);
  border-radius: 10px;
  border-color: var(--dark-10);
  outline: none;
  background-clip: padding-box;
  @media (max-width: 767px) {
    font-size: 16px;
  }
  &::placeholder {
    color: var(--dark-40);
  }
  &:focus {
    border-color: var(--blue-main);
  }
}
</style>
