<template>
  <svg @click="clickBtn" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 19L19 5M5 5L19 19" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  methods: {
    clickBtn() {
      this.$emit("btnSubmit");
    }
  }
};
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
  color: var(--dark-40);
  width: 18px;
  height: 18px;
  margin-right: -4px;
  margin-left: 4px;
}
</style>
