<template>
  <div class="edit-btn" @click="$emit('btnSubmit')">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 14H5.13144L13.3515 5.77998C13.7667 5.36472 14 4.80152 14 4.21426C14 3.627 13.7667 3.0638 13.3515 2.64854C12.9362 2.23329 12.373 2 11.7857 2C11.1985 2 10.6353 2.23329 10.22 2.64854L2 10.8686V14Z" fill="#3CAFE9" />
    </svg>
    <span class="s-text-caption">
      <slot></slot>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.edit-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
}
span {
  color: var(--dark-40);
  &:hover {
    color: var(--blue-main);
  }
}
svg {
  margin-bottom: 0px;
  margin-right: 4px;
}
</style>
