<template>
  <div class="custom-select" :class="{ open: open }" :tabindex="tabindex" @blur="selectBlur">
    <span v-if="label" class="label s-text-caption">{{ label }}</span>
    <div class="selected" @click="selectShow">
      {{ selected }} <span v-if="toOptionText">{{ toOptionText }}</span>
      <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L4.5 5L8 1" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
    <div v-if="!optionsKey" class="items" :class="{ selectHide: !open }">
      <div class="items-inner">
        <div v-for="(option, i) of options" :key="i" @click="selectClick(option)">
          {{ option }}
        </div>
      </div>
    </div>
    <div v-if="optionsKey" class="items" :class="{ selectHide: !open }">
      <div class="items-inner">
        <div :class="{ blue: checkOption(option) }" v-for="(option, i) of options" :key="i" @click="selectClick(option)">
          <svg v-if="checkOption(option)" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.04319 9L12.6014 1M4.98511 8.99975L1.40137 5.17569" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span>{{ option[optionsKey] }}</span>
          <span v-if="toOptionText">{{ toOptionText }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    valid: {
      type: Boolean,
      default: true
    },
    optionsKey: {
      type: String
    },
    toOptionText: {
      type: String
    },
    options: {
      type: Array,
      required: true
    },
    default: {
      type: [String, Object, Number],
      required: false,
      default: "0"
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      selected: this.default ? this.default : this.options.length > 0 ? this.placeholder : null,
      open: false
    };
  },
  methods: {
    checkOption(element) {
      if (element[this.optionsKey] == this.selected) return true;
      return false;
    },
    selectShow(event) {
      this.open = !this.open;

      const item = event.target.closest(".custom-select");

      if (this.open) {
        const height = item.querySelector(".items-inner").offsetHeight + 8;
        item.querySelector(".items").style.height = height + "px";
      } else {
        item.querySelector(".items").style.height = null;
      }
    },
    selectBlur(event) {
      this.open = false;

      const item = event.target.closest(".custom-select");

      if (this.open) {
        const height = item.querySelector(".items-inner").offsetHeight + 8;
        item.querySelector(".items").style.height = height + "px";
      } else {
        item.querySelector(".items").style.height = null;
      }
    },

    selectClick(option) {
      if (this.optionsKey) {
        this.selected = option[this.optionsKey];
      } else {
        this.selected = option;
      }
      this.open = false;
      this.$emit("input", option);

      const selectArr = document.querySelectorAll(".custom-select");

      selectArr.forEach(function(item) {
        item.querySelector(".items").style.height = null;
      });
    }
  },
  mounted() {
    this.$emit("input", this.selected);
  }
};
</script>

<style lang="scss" scoped>
.custom {
  &-select {
    margin-bottom: 39px;
    position: relative;
    max-width: 325px;
    width: 100%;
    font-size: 14px;
    text-align: left;
    outline: none;
    height: 47px;
    line-height: 52px;
    transition: 0.5s ease;
    .selected {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 49px;
      color: var(--dark-100);
      border-radius: 6px;
      border: 1px solid var(--dark-10);
      background: var(--bg-white);
      padding: 0 35px 0 15px;
      cursor: pointer;
      user-select: none;
      transition: all 0.2s;
      letter-spacing: 0.03em;
      @media (max-width: 767px) {
        font-size: 16px;
      }

      &.active {
        color: initial;
      }

      svg {
        position: absolute;
        top: 22px;
        right: 1em;
        transition: 0.5s ease;
      }
    }

    &.open {
      .selected {
        border-color: var(--blue-main);
        border-radius: 6px 6px 0 0;
        svg {
          transform: rotate(-180deg);
        }
      }

      .label {
        color: var(--blue-main);
      }
    }

    .items {
      border-radius: 0px 0px 6px 6px;
      overflow: hidden;
      border-left: 1px solid var(--dark-10);
      border-right: 1px solid var(--dark-10);
      border-bottom: 1px solid var(--dark-10);
      background: var(--bg-white);
      position: absolute;
      top: calc(100% - 2px);
      left: 0;
      right: 0;
      height: 0;
      z-index: 1;
      transition: 200ms ease;
      &-inner {
        max-height: 140px;
        overflow-y: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &-inner div {
        color: var(--dark-100);
        padding-left: 25px;
        cursor: pointer;
        user-select: none;
        position: relative;
        line-height: 34px;

        svg {
          position: absolute;
          top: calc(50% - 7px);
          left: 7px;
        }

        &:hover {
          color: var(--blue-main);
        }
        &.blue {
          color: var(--blue-main);
        }
      }

      &:not(.selectHide) {
        border-color: var(--blue-main);
        opacity: 1;
      }
    }

    &.not-valid {
      .selected {
        border-color: var(--error);
      }

      svg path {
        stroke: var(--error);
      }

      .label {
        color: var(--error);
      }
    }
  }
}

.selectHide {
  opacity: 0;
}

.label {
  top: -11px;
  left: 16px;
  position: absolute;
  color: var(--dark-40);
  padding: 0 4px;
  margin-left: -4px;
  background: var(--white-100);
  font-weight: 400;
}
</style>
