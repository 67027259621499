<template>
  <div class="setting-page">
    <Dialog v-if="dialogLogout" @submitDialog="Logout" @cancelDialog="dialogLogout = false" v-model:show="dialogLogout" firstBtnMode="error" :secondBtnText="SETTINGS_TRANSLATE?.modals?.logout?.btn?.cancel" :firstBtnText="SETTINGS_TRANSLATE?.modals?.logout?.btn?.confirm">
      <template v-slot:title>{{ SETTINGS_TRANSLATE?.modals.logout.title }}</template>
    </Dialog>

    <Dialog class="dialog-review" @submitDialog="submitReview" @cancelDialog="dialogSendReview.show = false" v-if="dialogSendReview.show" v-model:show="dialogSendReview.show" :firstBtnText="SETTINGS_TRANSLATE?.modals?.reviews?.btn?.send" :secondBtnText="SETTINGS_TRANSLATE?.modals?.reviews?.btn?.cancel">
      <template v-slot:title> {{ SETTINGS_TRANSLATE?.modals?.reviews?.title }} </template>
      <span class="message m-text-des-n gray">{{ SETTINGS_TRANSLATE?.modals?.reviews?.description }}</span>
      <div class="input-container">
        <InputSelectSetting @input="selectReviewType" :default="SETTINGS_TRANSLATE?.reviews.types[0].name" optionsKey="name" :options="SETTINGS_TRANSLATE?.reviews?.types" :label="SETTINGS_TRANSLATE?.modals?.reviews?.type"></InputSelectSetting>
        <InputSelectSetting @input="selectReviewDish" v-if="dialogSendReview.reviewType?.is_dish" :default="dishesList?.[0]?.name" optionsKey="name" :options="dishesList" :label="SETTINGS_TRANSLATE?.modals?.reviews?.dish" :key="dishSelectKey"></InputSelectSetting>
      </div>
      <div class="futer-conatainer">
        <TextArea v-model:value="dialogSendReview.reviewText" :placeholder="SETTINGS_TRANSLATE?.modals?.reviews?.comment"></TextArea>
        <div class="rating-box">
          <span class="s-text-alert">{{ SETTINGS_TRANSLATE?.modals?.reviews?.rate }}</span>
          <div class="rating">
            <div class="star" @mouseenter="starHover(item)" @mouseleave="starHoverDecline" @click="selectReviewRating(item)" :class="{ active: item <= dialogSendReview.reviewRating }" v-for="item in 5" :key="item">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3343 1.04893C11.5438 0.404017 12.4562 0.404018 12.6657 1.04893L14.986 8.19007C15.0797 8.47849 15.3485 8.67376 15.6518 8.67376H23.1604C23.8385 8.67376 24.1205 9.54149 23.5719 9.94007L17.4972 14.3535C17.2519 14.5318 17.1492 14.8477 17.243 15.1362L19.5633 22.2773C19.7728 22.9222 19.0347 23.4585 18.4861 23.0599L12.4114 18.6465C12.1661 18.4682 11.8339 18.4682 11.5886 18.6465L5.51393 23.0599C4.96533 23.4585 4.2272 22.9222 4.43675 22.2773L6.75704 15.1362C6.85075 14.8477 6.74809 14.5318 6.50275 14.3535L0.428136 9.94007C-0.120464 9.54149 0.16148 8.67376 0.839586 8.67376H8.34822C8.65148 8.67376 8.92025 8.47849 9.01396 8.19007L11.3343 1.04893Z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </Dialog>

    <Dialog class="dialog-edit-company" @submitDialog="submitEditCompanyDialog" @cancelDialog="cancelEditCompanyDialog" v-if="dialogEditCompany.show" v-model:show="dialogEditCompany.show" :secondBtnText="SETTINGS_TRANSLATE?.modals?.company?.btn?.cancel" :firstBtnText="SETTINGS_TRANSLATE?.modals?.company?.btn?.confirm">
      <template v-slot:title>{{ SETTINGS_TRANSLATE?.modals.company.title }}</template>
      <span class="message m-text-des-n-norm">{{ SETTINGS_TRANSLATE?.modals.company.description }}</span>
      <div class="inputs-box">
        <InputText v-model:value.trim="dialogEditCompany.companyName" :label="SETTINGS_TRANSLATE?.modals?.company?.name?.label" class="required"></InputText>
        <InputTextarea v-model:value.trim="dialogEditCompany.companyAdress" :label="SETTINGS_TRANSLATE?.modals?.company?.address?.label" class="required"></InputTextarea>
      </div>
    </Dialog>

    <Dialog class="dialog-edit-member" v-show="dialogMemberLimit.show" v-model:show="dialogMemberLimit.show" @submitDialog="saveNewLimits" @cancelDialog="cancelEditLimits" :secondBtnText="SETTINGS_TRANSLATE?.modals?.limits?.btn?.cancel" :firstBtnText="SETTINGS_TRANSLATE?.modals?.limits?.btn?.confirm">
      <template v-slot:title>{{ SETTINGS_TRANSLATE?.modals?.limits?.title }}</template>
      <div class="inputs-box">
        <InputText @blurInput="changeValueMemberLimit(limit)" v-for="(limit, index) in dialogMemberLimit.startArr" type="number" v-model:value.trim="limit.sum" :key="index" :label="limit.is_guest ? `${SETTINGS_TRANSLATE?.modals?.limits?.guest_limit?.label}` : `${SETTINGS_TRANSLATE?.modals?.limits?.name?.label} ${index + 1}`" autocomplete="off">
          <ButtonClose @click="removeLimitMember(index, limit)" v-if="!limit.is_guest"></ButtonClose>
          <InfoIcon v-if="limit.is_guest" bottom="28px" left="-24px" arrowDir="bl">
            {{ SETTINGS_TRANSLATE?.tooltips.company.guest_limit }}
          </InfoIcon>
        </InputText>
        <div class="main">
          <div class="input-box">
            <input id="newLimit" @keyup.enter="addNewLimitMemeber" :placeholder="SETTINGS_TRANSLATE?.modals?.limits?.new?.placeholder" type="number" class="input m-text-des" autocomplete="off" />
            <span class="label m-text-food-title">{{ SETTINGS_TRANSLATE?.modals?.limits?.new?.label }}</span>
            <svg @click="addNewLimitMember_2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 448 512"><path d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z" /></svg>
          </div>
        </div>
      </div>
    </Dialog>

    <Dialog class="dialog-about-me" :class="{ firstModal: !USER?.name || !USER?.last_name }" @submitDialog="submitEditMe" @cancelDialog="cancelEditMe" v-show="dialogAboutMe?.show || !USER?.name || !USER?.last_name" v-model:show="dialogAboutMe.show" :secondBtnText="SETTINGS_TRANSLATE?.modals?.user?.btn?.cancel" :firstBtnText="SETTINGS_TRANSLATE?.modals?.user?.btn?.confirm">
      <template v-slot:title>{{ SETTINGS_TRANSLATE?.modals.user.title }}</template>
      <div class="inputs-box">
        <InputText class="required" v-model:value.trim="dialogAboutMe.name" :valid="validName" :label="SETTINGS_TRANSLATE?.user?.name"></InputText>
        <InputText class="required" v-model:value.trim="dialogAboutMe.last_name" :valid="validLast_name" :label="SETTINGS_TRANSLATE?.user?.last_name"></InputText>
        <InputText class="required" v-model:value.trim="dialogAboutMe.email" :errorText="VALIDATION_TRANSLATE?.employees?.email" :valid="validEmail" :label="SETTINGS_TRANSLATE?.user.email"></InputText>
        <InputText class="required" v-model:value.trim="dialogAboutMe.phone" :errorText="VALIDATION_TRANSLATE?.employees?.phone" :valid="validPhone" id="myPhoneNumber" :label="SETTINGS_TRANSLATE?.user?.phone"></InputText>
        <InputSelect class="required" v-if="USER?.role.id == 2 && COMPANY" :default="USER?.limit?.sum" :options="COMPANY?.limits" selectType="limit" @selectPressed="selectMyLimit" :label="SETTINGS_TRANSLATE?.user.limit"></InputSelect>
        <InputText v-model:value.trim="dialogAboutMe.birthday" :errorText="VALIDATION_TRANSLATE?.employees?.min_order" :valid="brithDay.valid" id="myBirthday" :label="SETTINGS_TRANSLATE?.user.birthday">
          <InfoIcon class="desktop-icon" bottom="28px" left="-24px" arrowDir="bl">
            {{ SETTINGS_TRANSLATE?.user?.birthday_tip }}
          </InfoIcon>
          <InfoIcon class="mobile-icon" bottom="28px" right="-24px" arrowDir="br">
            {{ SETTINGS_TRANSLATE?.user?.birthday_tip }}
          </InfoIcon>
        </InputText>
        <InputText v-if="USER?.role.id === 2 && COMPANY" v-model:value.trim="dialogAboutMe.min_order" :errorText="VALIDATION_TRANSLATE?.employees?.min_order" :valid="validMinOrder" :label="SETTINGS_TRANSLATE?.user?.min_order"></InputText>
      </div>
    </Dialog>

    <Dialog class="dialog-about-me" @submitDialog="submitCardDialog" @cancelDialog="cancelCardDialog" v-show="dialogCard" v-model:show="dialogCard" :firstBtnText="SETTINGS_TRANSLATE?.payment?.card?.modal?.add?.btn?.add" :secondBtnText="SETTINGS_TRANSLATE?.payment?.card?.modal?.add?.btn?.cancel">
      <template v-slot:title>{{ SETTINGS_TRANSLATE?.payment?.card?.modal?.add?.title }}</template>
      <div class="inputs-box">
        <InputText id="card" v-model:value.trim="cardAddData.number" :errorText="SETTINGS_TRANSLATE?.payment?.card?.modal?.add?.validation?.card" :valid="cardNumValid" :label="SETTINGS_TRANSLATE?.payment?.card?.modal?.add?.card" class="cardNum"></InputText>
        <InputText v-model:value.trim="cardAddData.date" :errorText="SETTINGS_TRANSLATE?.payment?.card?.modal?.add?.validation?.expires" :valid="cardDateValid" :label="SETTINGS_TRANSLATE?.payment?.card?.modal?.add?.expires" class="cardDate"></InputText>
      </div>
    </Dialog>

    <Dialog v-if="cardRemoveDialog" @submitDialog="submitCardRemove" @cancelDialog="cardRemoveDialog = false" v-model:show="cardRemoveDialog" :firstBtnText="SETTINGS_TRANSLATE?.payment?.card?.modal?.remove?.btn?.confirm" :secondBtnText="SETTINGS_TRANSLATE?.payment?.card?.modal?.add?.btn?.cancel" firstBtnMode="error">
      <template v-slot:title>{{ SETTINGS_TRANSLATE?.payment?.card?.modal?.remove?.title }}</template>
    </Dialog>

    <Dialog class="dialog-edit-pass" v-show="dialogChangePass.show" v-model:show="dialogChangePass.show" @cancelDialog="cancelChangePass" @submitDialog="submitChangePass" :secondBtnText="SETTINGS_TRANSLATE?.modals?.password?.btn?.cancel" :firstBtnText="SETTINGS_TRANSLATE?.modals?.password?.btn?.confirm">
      <template v-slot:title>{{ SETTINGS_TRANSLATE?.modals?.password?.title }}</template>
      <div class="inputs-box">
        <InputText :errorText="VALIDATION_TRANSLATE?.settings?.password" :valid="dialogChangePass.valueInput1.length > 7" v-model:value.trim="dialogChangePass.valueInput1" :type="dialogChangePass.passwordInputType1" :label="SETTINGS_TRANSLATE?.modals?.password?.old_password?.label">
          <ButtonShowPass v-model:type="dialogChangePass.passwordInputType1"></ButtonShowPass>
        </InputText>
        <InputText :errorText="VALIDATION_TRANSLATE?.settings?.password" :valid="dialogChangePass.valueInput2.length > 7" v-model:value.trim="dialogChangePass.valueInput2" :type="dialogChangePass.passwordInputType2" :label="SETTINGS_TRANSLATE?.modals?.password?.new_password?.label">
          <ButtonShowPass v-model:type="dialogChangePass.passwordInputType2"></ButtonShowPass>
        </InputText>
      </div>
    </Dialog>
    <DialogChooseEmployee v-model:show="CHOOSE_EMPLOYEE"></DialogChooseEmployee>
    <div class="main page-content">
      <div class="title-box">
        <h1 class="r-title-1">{{ SETTINGS_TRANSLATE?.common.title }}</h1>
        <Button @click="dialogLogout = true" mode="white">{{ SETTINGS_TRANSLATE?.common?.btn?.logout }}</Button>
      </div>
      <div class="content m-text-food-title">
        <div class="l-box ">
          <div class="card">
            <div class="card-header" v-if="COMPANY">
              <span class="card-title s-text-caption">{{ SETTINGS_TRANSLATE?.company?.title }}</span>
              <ButtonEdit @click="editCompany" v-if="COMPANY?.need_updated != true && USER?.role.id == 2">{{ SETTINGS_TRANSLATE?.common?.btn?.edit }}</ButtonEdit>
              <div v-if="COMPANY?.need_updated && USER?.role.id == 2" class="info-company">
                <InfoIcon bottom="28px" left="-24px" arrowDir="bl">
                  {{ SETTINGS_TRANSLATE?.tooltips?.company?.update }}
                </InfoIcon>
                <span class="s-text-alert">{{ SETTINGS_TRANSLATE?.company?.update?.label }}</span>
              </div>
            </div>
            <div class="card-content">
              <div class="card-cell-50">
                <span class="cell-label s-text-caption">{{ SETTINGS_TRANSLATE?.company?.name?.label }}</span>
                <span class="cell-text">{{ COMPANY?.name }} </span>
              </div>
              <div class="card-cell-50">
                <span class="cell-label s-text-caption">{{ SETTINGS_TRANSLATE?.company?.address?.label }}</span>
                <span class="cell-text">{{ COMPANY?.address }} </span>
              </div>
            </div>
          </div>

          <div class="card" v-if="USER?.role.id == 2">
            <div class="card-header">
              <span class="card-title s-text-caption">{{ SETTINGS_TRANSLATE?.limits?.title }}</span>
              <ButtonEdit @click="editLimits">{{ SETTINGS_TRANSLATE?.common?.btn?.edit }}</ButtonEdit>
            </div>
            <div class="card-content limits-list">
              <div class="card-cell-25" :class="{ guest: limit.is_guest }" v-for="(limit, index) in limitList" :key="limit.id">
                <span v-if="limit.is_guest" class="cell-label s-text-caption">{{ SETTINGS_TRANSLATE?.limits?.guest_limit?.label }}</span>
                <span v-else class="cell-label s-text-caption">{{ SETTINGS_TRANSLATE?.limits?.name?.label }} {{ index + 1 }} </span>
                <div class="info">
                  <InfoIcon v-if="limit.is_guest" class="info-1" bottom="28px" left="-24px" arrowDir="bl">
                    {{ SETTINGS_TRANSLATE?.tooltips?.company?.guest_limit }}
                  </InfoIcon>
                  <span class="cell-text"> {{ limit?.sum }} ₴ </span>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <span class="card-title s-text-caption">{{ SETTINGS_TRANSLATE?.languages.title }}</span>
            </div>
            <div class="language-box">
              <label class="language s-text-alert" v-for="lang in OTHER_TRANSLATE?.languages" :key="lang.code">
                <input type="radio" name="language" v-model="langCode" :value="lang.code" :checked="checkedLang(lang.code)" />
                <div class="check-box">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.0643 18L20.4016 6M8.97718 17.9996L3.60156 12.2635" stroke="currentColor" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <span class="language-name">{{ lang.name }}</span>
              </label>
            </div>
          </div>

          <div class="card">
            <div class="info second">
              <InfoIcon bottom="28px" left="-24px" arrowDir="bl">
                {{ SETTINGS_TRANSLATE?.tooltips?.company?.delivery_time?.label }} <a :href="`tel:${SETTINGS_TRANSLATE?.tooltips?.company?.delivery_time?.phone}`">{{ SETTINGS_TRANSLATE?.tooltips?.company?.delivery_time?.phone }}</a>
              </InfoIcon>
              <span class="card-title s-text-caption">{{ SETTINGS_TRANSLATE?.company.delivery_time }} {{ COMPANY?.delivery_time }} </span>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <span class="card-title s-text-caption">{{ SETTINGS_TRANSLATE?.reviews.label_1 }} <span class="blue">B Lunch</span></span>
            </div>
            <div class="card-content review-btn-fix">
              <Button @click="openReview" class="card-btn-blue">{{ SETTINGS_TRANSLATE?.reviews.btn }}</Button>
            </div>
          </div>
        </div>
        <div class="r-box">
          <div class="card">
            <div class="card-header">
              <span class="card-title s-text-caption">{{ SETTINGS_TRANSLATE?.user.title }} </span>
              <ButtonEdit @click="editMe">{{ SETTINGS_TRANSLATE?.common.btn.edit }}</ButtonEdit>
            </div>
            <div class="card-content">
              <div class="card-cell-50">
                <span class="cell-label s-text-caption">{{ SETTINGS_TRANSLATE?.user.name }} </span>
                <span class="cell-text">{{ USER?.name }} </span>
              </div>
              <div class="card-cell-50">
                <span class="cell-label s-text-caption">{{ SETTINGS_TRANSLATE?.user.last_name }}</span>
                <span class="cell-text">{{ USER?.last_name }}</span>
              </div>
              <div class="card-cell-50">
                <span class="cell-label s-text-caption">{{ SETTINGS_TRANSLATE?.user.email }}</span>
                <span class="cell-text">{{ USER?.email }}</span>
              </div>
              <div class="card-cell-50">
                <span class="cell-label s-text-caption">{{ SETTINGS_TRANSLATE?.user.phone }}</span>
                <span class="cell-text">{{ phoneFormat }}</span>
              </div>
              <div class="card-cell-50">
                <span class="cell-label s-text-caption">{{ SETTINGS_TRANSLATE?.user.role }}</span>
                <span class="cell-text">{{ USER?.role?.label }}</span>
              </div>
              <div class="card-cell-50">
                <span class="cell-label s-text-caption">{{ SETTINGS_TRANSLATE?.user.limit }}</span>
                <span v-if="USER?.limit" class="cell-text">{{ USER?.limit?.sum }} ₴</span>
                <span v-if="!USER?.limit" class="cell-text">0 ₴</span>
              </div>
              <div class="card-cell-50">
                <span class="cell-label s-text-caption">{{ SETTINGS_TRANSLATE?.user.birthday }}</span>
                <span class="cell-text" v-if="USER?.birthday">{{ `${bDayFormat(USER?.birthday).dd}/${bDayFormat(USER?.birthday).mm}/${bDayFormat(USER?.birthday).yyyy}` }}</span>
                <span class="cell-text" v-else>{{ SETTINGS_TRANSLATE?.user.birthday_empty }}</span>
              </div>
              <div v-if="USER?.min_order" class="card-cell-50">
                <span class="cell-label s-text-caption">{{ SETTINGS_TRANSLATE?.user?.min_order }}</span>
                <span  class="cell-text">{{ USER?.min_order }} ₴</span>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <span class="card-title s-text-caption">{{ SETTINGS_TRANSLATE?.password.title }}</span>
              <ButtonEdit @click="changePass">{{ SETTINGS_TRANSLATE?.common.btn.edit }}</ButtonEdit>
            </div>
          </div>
          <div class="card">
            <div v-for="categoryAllergen in PREFERENCES_ALL" :key="categoryAllergen.name">
              <div v-if="categoryAllergen?.items?.length > 0" class="card-header">
                <span class="card-title s-text-caption">{{ categoryAllergen.name }}</span>
              </div>
              <div v-if="categoryAllergen?.items?.length > 0" class="card-content review-btn-fix">
                <div class="checkbox-box">
                  <div @click="switchAllergen(allergen)" class="pref-checkbox s-text-caption" :class="{ active: checkForAllergen(allergen) }" v-for="allergen in categoryAllergen?.items" :key="allergen.id">
                    {{ allergen.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer class="footer-wrapper"></Footer>
  </div>
</template>

<script>
import Inputmask from "inputmask";
import Button from "@/components/parts/button.vue";
import DialogChooseEmployee from "@/components/dialogs/choose-employee.vue";
import ButtonShowPass from "@/components/parts/button-show-pass";
import ButtonClose from "@/components/parts/button-close.vue";
import InputText from "@/components/parts/input-text.vue";
import InputTextarea from "@/components/parts/input-textarea-settings.vue";
import InputSelectSetting from "@/components/parts/input-select-setting.vue";
import InputSelect from "@/components/parts/input-select";
import TextArea from "@/components/parts/text-area.vue";
// import ButtonAdd from "@/components/parts/button-add.vue";
import ButtonEdit from "@/components/parts/button-edit.vue";
import InfoIcon from "@/components/parts/info-icon.vue";
import Footer from "@/components/footer-setting.vue";
import Dialog from "@/components/dialogs/dialog-submit-setting.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    DialogChooseEmployee,
    Button,
    ButtonShowPass,
    ButtonClose,
    // ButtonAdd,
    InputText,
    InputTextarea,
    InputSelectSetting,
    InputSelect,
    TextArea,
    ButtonEdit,
    InfoIcon,
    Footer,
    Dialog
  },
  data() {
    return {
      dishSelectKey: 1,
      cardRemoveDialog: false,
      langCode: null,
      testBirthDay: "",
      alert: false,
      dialogSubmit: false,
      dialogLogout: false,
      dialogCard: false,
      cardAddData: {
        number: "",
        date: "",
        cvv: null
      },
      cardRemoveData: null,
      dialogSendReview: {
        show: false,
        reviewType: null,
        reviewDish: null,
        reviewText: "",
        reviewRating: 0
      },
      dialogEditCompany: {
        show: false,
        companyName: "",
        companyAdress: ""
      },
      dialogMemberLimit: {
        show: false,
        startArr: [],
        createdArr: [],
        deletedArr: [],
        changedArr: []
      },
      dialogAboutMe: {
        show: false,
        name: "",
        last_name: "",
        email: "",
        phone: "",
        role: null,
        limit: null,
        birthday: "",
        min_order: null,
      },
      dialogChangePass: {
        show: false,
        passwordInputType1: "password",
        passwordInputType2: "password",
        valueInput1: "",
        valueInput2: ""
      }
    };
  },
  methods: {
    ...mapActions(["GetOrders", "DishesTranslate", "GetCards", "RemoveCard", "AddCard", "ValidationTranslate", "UpdateCompany", "EmployeesList", "SendReview", "EditCompanyAct", "GetSeo", "GetAllDishes", "AboutMe", "SummonAlert", "SettingsTranslate", "ChangePassword", "Logout", "EditEmployeeSettings", "GetLimits", "OtherTranslate", "EditLimits", "GetAllPreferences", "Roles"]),
    starHover(item) {
      let stars = document.querySelectorAll(".rating .star");
      let hovered = Array.from(stars).slice(0, item);
      hovered.forEach(elem => {
        elem.classList.add("hovered");
      });
    },
    starHoverDecline() {
      let stars = document.querySelectorAll(".rating .star");
      Array.from(stars).forEach(elem => {
        elem.classList.remove("hovered");
      });
    },
    checkForAllergen(allergenIn) {
      let x = false;
      this.USER?.preferences?.allergen.forEach(e => {
        if (e.id == allergenIn.id) x = true;
      });
      this.USER?.preferences?.other.forEach(e => {
        if (e.id == allergenIn.id) x = true;
      });
      return x;
    },
    async userModal() {
      document.querySelector("body").style.overflow = "hidden";
      this.$store.commit("Set_Choose_Employee", true);
      await this.EmployeesList();
    },
    addCard() {
      document.querySelector("body").style.overflow = "hidden";
      this.cardAddData.number = "";
      this.cardAddData.date = "";
      this.dialogCard = true;
    },
    async switchAllergen(allergenIn) {
      let preferences = {};
      preferences.allergen = [];
      preferences.other = [];

      if (allergenIn.type == "allergen") {
        preferences.allergen.push(allergenIn.id);
      } else if (allergenIn.type == "other") {
        preferences.other.push(allergenIn.id);
      }

      let temp = { preferences };
      let res = await this.EditEmployeeSettings({
        user: this.USER.id,
        company: this.COMPANY.id,
        temp
      });
      if (res) await this.GetAllPreferences();
    },
    selectReviewType(item) {
      if (typeof item == "object") {
        this.dialogSendReview.reviewType = item;
      }
    },
    selectReviewDish(item) {
      if (typeof item == "object") {
        this.dialogSendReview.reviewDish = item;
      }
    },
    selectReviewRating(item) {
      this.dialogSendReview.reviewRating = item;
    },
    submitReview() {
      if (this.dialogSendReview.reviewType.is_dish == 1 && this.dialogSendReview.reviewDish == null) {
        this.dialogSendReview.reviewDish = JSON.parse(JSON.stringify(this.DISHES?.[0]));
      }
      let data = {
        type_id: this.dialogSendReview.reviewType.id,
        comment: this.dialogSendReview.reviewText,
        rate: this.dialogSendReview.reviewRating
      };
      if (this.dialogSendReview.reviewType.is_dish != 1) {
        this.dialogSendReview.reviewDish = null;
        data.dish_id = null;
      } else {
        data.dish_id = this.dialogSendReview.reviewDish.id;
      }
      this.SendReview(data);
      document.querySelector("body").style.overflow = null;
      this.dialogSendReview.show = false;
    },
    openReview() {
      this.dialogSendReview = {
        show: true,
        reviewDish: null,
        reviewText: "",
        reviewRating: 0
      };
      this.dialogSendReview.reviewType = this.SETTINGS_TRANSLATE?.reviews.types[0];
      document.querySelector("body").style.overflow = "hidden";
    },
    checkedLang(lang) {
      let locLang = localStorage.getItem("lang") ?? "uk";
      return lang == locLang;
    },
    sortLimitsSum(limitsArr) {
      let sort = limitsArr.sort((a, b) => {
        if (a.sum > b.sum) return 1;
        if (a.sum < b.sum) return -1;
        return 0;
      });
      return sort;
    },
    saveNewLimits() {
      this.EditLimits({
        put: this.dialogMemberLimit.changedArr,
        post: this.dialogMemberLimit.createdArr,
        del: this.dialogMemberLimit.deletedArr
      });
      this.cancelEditLimits();
      document.querySelector("body").style.overflow = null;
    },
    addNewLimitMemeber(e) {
      if (e.target.value) {
        let date = new Date();
        let newLimit = {
          created: date.getTime(),
          sum: e.target.value,
          is_guest: 0
        };
        this.dialogMemberLimit.createdArr.push(newLimit);
        this.dialogMemberLimit.startArr.splice(this.dialogMemberLimit.startArr.length - 1, 0, newLimit);
      }
      e.target.value = "";
    },
    addNewLimitMember_2() {
      const target = document.querySelector("#newLimit");
      if (target.value) {
        let date = new Date();
        let newLimit = {
          created: date.getTime(),
          sum: target.value,
          is_guest: 0
        };
        this.dialogMemberLimit.createdArr.push(newLimit);
        this.dialogMemberLimit.startArr.splice(this.dialogMemberLimit.startArr.length - 1, 0, newLimit);
      }
      target.value = "";
    },
    removeLimitMember(index, limit) {
      if (limit.id) {
        let foundIndex = null;
        let items = this.dialogMemberLimit.changedArr;
        this.dialogMemberLimit.deletedArr.push(limit);
        foundIndex = items.findIndex(x => x.id == limit.id);

        if (foundIndex !== -1) {
          items.splice(foundIndex, 1);
        }
      } else {
        let createdArray = this.dialogMemberLimit.createdArr;
        let foundIndex = createdArray.findIndex(x => x.created == limit.created);
        if (foundIndex !== -1) {
          createdArray.splice(foundIndex, 1);
        }
      }

      this.dialogMemberLimit.startArr.splice(index, 1);
    },
    changeValueMemberLimit(limit) {
      let changeArray = this.dialogMemberLimit.changedArr;
      let foundIndex = null;
      if (limit.id) {
        foundIndex = changeArray.findIndex(x => x.id == limit.id);

        if (foundIndex == -1) {
          changeArray.push(limit);
        } else {
          changeArray[foundIndex] = limit;
        }
      } else {
        let createdArray = this.dialogMemberLimit.createdArr;
        foundIndex = createdArray.findIndex(x => x.created == limit.created);

        if (foundIndex !== -1) {
          createdArray[foundIndex] = limit;
        }
      }
    },
    cancelEditLimits() {
      this.dialogMemberLimit.show = false;
    },
    editLimits() {
      this.dialogMemberLimit = {
        show: true,
        startArr: [],
        createdArr: [],
        deletedArr: [],
        changedArr: []
      };
      this.dialogMemberLimit.startArr = JSON.parse(JSON.stringify(this.limitList));
      document.querySelector("body").style.overflow = "hidden";
    },
    submitCardDialog() {
      let cardFormat = this.cardAddData.number.replace(/\s/g, "");
      this.AddCard({
        data: {
          card: cardFormat,
          expires: this.cardAddData.date
        }
      });
      this.dialogCard = false;
    },
    cancelCardDialog() {
      this.cardAddData.number = "";
      this.cardAddData.date = "";
      this.dialogCard = false;
    },
    cardRemove(item) {
      this.cardRemoveData = item.id;
      this.cardRemoveDialog = true;
    },
    async submitCardRemove() {
      this.cardRemoveDialog = false;
      this.RemoveCard({
        data: {
          card: this.cardRemoveData
        }
      });
    },
    async submitEditMe() {
      let temp = {};
      if (this.dialogAboutMe.name && this.validName) temp.name = this.dialogAboutMe.name;
      if (this.dialogAboutMe.last_name && this.validLast_name) temp.last_name = this.dialogAboutMe.last_name;
      if (this.dialogAboutMe.email && this.validEmail) temp.email = this.dialogAboutMe.email;
      if (this.dialogAboutMe.phone && this.validPhone) {
        temp.phone = this.dialogAboutMe.phone
          .split("")
          .filter(e => +e || e === "0")
          .join("");

        if (temp.phone.length < 10) temp.phone = "380" + temp.phone;
      }

      if (this.dialogAboutMe.role !== null) {
        temp.role = this.dialogAboutMe.role;
      }
      if (this.dialogAboutMe.limit !== null) {
        temp.limit_id = this.dialogAboutMe.limit;
      }
      if (!this.dialogAboutMe.min_order || this.dialogAboutMe.min_order > 0) {
        temp.min_order = !this.dialogAboutMe.min_order ? null : this.dialogAboutMe.min_order;
      }
      if (this.dialogAboutMe.birthday && this.brithDay.valid) {
        temp.birthday = `${this.brithDay.yyyy}-${this.brithDay.mm}-${this.brithDay.dd}`;
      }
      if (Object.keys(temp).length > 0) {
        await this.EditEmployeeSettings({
          user: this.USER.id,
          company: this.COMPANY.id,
          temp
        });
        this.cancelEditMe();
        document.querySelector("body").style.overflow = null;
      }
    },
    cancelEditMe() {
      if (!this.USER.name || !this.USER.last_name) {
        document.querySelector("body").style.overflow = "overflowed";
      }
      this.dialogAboutMe.show = false;
      this.dialogAboutMe.name = this.USER.name;
      this.dialogAboutMe.last_name = this.USER.last_name;
      this.dialogAboutMe.email = this.USER.email;
      this.dialogAboutMe.phone = this.USER.phone ? this.USER.phone.replace("380", "") : "";
      this.dialogAboutMe.role = null;
      this.dialogAboutMe.limit = null;
      if (this.USER.birthday) {
        let bday = this.bDayFormat(this.USER.birthday);
        this.dialogAboutMe.birthday = `${bday.dd} / ${bday.mm} / ${bday.yyyy}`;
      }
    },
    editMe() {
      document.querySelector("body").style.overflow = "hidden";
      this.dialogAboutMe.show = true;
      this.dialogAboutMe.name = this.USER.name;
      this.dialogAboutMe.last_name = this.USER.last_name;
      this.dialogAboutMe.email = this.USER.email;
      this.dialogAboutMe.phone = this.USER.phone ? this.USER.phone.replace("380", "") : "";
      this.dialogAboutMe.role = null;
      this.dialogAboutMe.limit = null;
      if (this.USER.birthday) {
        let bday = this.bDayFormat(this.USER.birthday);
        this.dialogAboutMe.birthday = `${bday.dd} / ${bday.mm} / ${bday.yyyy}`;
      }
    },
    bDayFormat(date) {
      if (date) {
        let x = date.split("-");
        let newDate = {
          yyyy: x[0],
          mm: x[1],
          dd: x[2]
        };
        return newDate;
      } else {
        return null;
      }
    },
    selectMyLimit(item) {
      if (typeof item == "object") {
        this.dialogAboutMe.limit = item.id;
      }
    },
    selectMyRole(item) {
      if (typeof item == "object") {
        this.dialogAboutMe.role = item.id;
      }
    },
    submitChangePass() {
      if (this.dialogChangePassValid) {
        let temp = {
          old_password: this.dialogChangePass.valueInput1,
          new_password: this.dialogChangePass.valueInput2
        };
        this.ChangePassword(temp);
        this.cancelChangePass();
        document.querySelector("body").style.overflow = null;
      } else {
        this.SummonAlert({ message: this.VALIDATION_TRANSLATE?.settings?.alert?.password?.edit?.failed, type: "error" });
      }
    },
    cancelChangePass() {
      this.dialogChangePass.show = false;
    },
    changePass() {
      this.dialogChangePass.valueInput1 = "";
      this.dialogChangePass.valueInput2 = "";
      this.dialogChangePass.show = true;
      document.querySelector("body").style.overflow = "hidden";
    },
    editCompany() {
      let temp = { ...this.COMPANY };
      this.dialogEditCompany.companyName = temp.name;
      this.dialogEditCompany.companyAdress = temp.address;
      this.dialogEditCompany.show = true;
      document.querySelector("body").style.overflow = "hidden";
    },
    cancelEditCompanyDialog() {
      this.dialogEditCompany.companyName = "";
      this.dialogEditCompany.companyAdress = "";
      this.dialogEditCompany.show = false;
    },
    submitEditCompanyDialog() {
      if (this.dialogEditCompany.companyName && this.dialogEditCompany.companyAdress) {
        this.EditCompanyAct({
          name: this.dialogEditCompany.companyName,
          address: this.dialogEditCompany.companyAdress
        });
        this.cancelEditCompanyDialog();
        document.querySelector("body").style.overflow = null;
      } else {
        this.SummonAlert({ message: "Заполните все поля!", type: "error" });
      }
    },
    maskedCard(card) {
      let format = card.split("");
      for (let i = 0; i < format.length - 4; i++) {
        format[i] = "•";
      }
      format.splice(7, 0, " ");
      let newFormat = format.join("").substr(3);
      return newFormat;
    }
  },
  computed: {
    ...mapGetters(["ORDERS", "CHOOSE_EMPLOYEE", "GET_CARDS", "VALIDATION_TRANSLATE", "COMPANY", "USER", "LIMITS", "OTHER_TRANSLATE", "SETTINGS_TRANSLATE", "DISHES", "PREFERENCES_ALL", "GET_ROLES"]),
    dishesList() {
      let newArr = [];
      if (this.DISHES) {
        const dishGroup = Object.values(this.DISHES);
        dishGroup.forEach(group => {
          newArr = newArr.concat(group);
        });
      }
      return newArr;
    },
    limitList() {
      if (!this.LIMITS) {
        return [];
      } else {
        let sortArrMinusGuest = this.LIMITS.filter(e => !e.is_guest);
        let sortArrGuest = this.LIMITS.filter(e => e.is_guest);
        let newList = [...sortArrMinusGuest, ...sortArrGuest];
        return newList;
      }
    },
    phoneFormat() {
      if (this.USER?.phone) {
        let tel = this.USER?.phone.split("");
        tel.splice(2, 0, " (");
        tel.splice(6, 0, ") ");
        tel.splice(10, 0, " ");
        tel.splice(13, 0, " ");
        tel.unshift("+");
        let newTel = tel.join("");
        return newTel;
      } else {
        return "";
      }
    },
    dialogChangePassValid() {
      if (this.dialogChangePass.valueInput1 && this.dialogChangePass.valueInput2 && this.dialogChangePass.valueInput1.length > 7 && this.dialogChangePass.valueInput2.length > 7) {
        return true;
      } else {
        return false;
      }
    },
    validEmail() {
      let pattern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      return pattern.test(this.dialogAboutMe.email);
    },
    validName() {
      return this.dialogAboutMe.name ? !this.dialogAboutMe.name.match(/[^А-Яа-яЁёЇїІіЄєҐґa-zA-Z]/) : true;
    },
    validMinOrder() {
      if (null === this.dialogAboutMe.min_order) {
        return true;
      }

      const isDigit = this.dialogAboutMe.min_order.toString().match(/^\d+$/);
      if (!isDigit) {
        return false;
      }

      if (this.dialogAboutMe.min_order <= 0) {
        return false;
      }

      return true;
    },
    validLast_name() {
      return this.dialogAboutMe.last_name ? !this.dialogAboutMe.last_name.match(/[^А-Яа-яЁёЇїІіЄєҐґa-zA-Z]/) : true;
    },
    validPhone() {
      if (this.dialogAboutMe.phone) {
        let phone = this.dialogAboutMe.phone
          .split("")
          .filter(e => +e || e === "0")
          .join("")
          .replace("380", "");
        if (phone.length < 9) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    brithDay() {
      let inputDate = {
        dd: this.dialogAboutMe.birthday.slice(0, 2),
        mm: this.dialogAboutMe.birthday.slice(5, 7),
        yyyy: this.dialogAboutMe.birthday.slice(10, 14)
      };
      let now = new Date();
      let date = new Date(inputDate.yyyy, inputDate.mm - 1, inputDate.dd);
      if (date.getFullYear() == inputDate.yyyy && date.getMonth() == inputDate.mm - 1 && date.getDate() == inputDate.dd && inputDate.yyyy >= 1950 && date.getTime() < now.getTime()) {
        inputDate.valid = true;
      } else {
        inputDate.valid = false;
      }
      return inputDate;
    },
    cardNumValid() {
      return !(this.cardAddData.number.length < 19);
    },
    cardDateValid() {
      return !(this.cardAddData.date.length < 5);
    }
  },
  watch: {
    async langCode() {
      localStorage.setItem("lang", this.langCode);
      this.SettingsTranslate();
      this.OtherTranslate();
      this.GetSeo();
      this.DishesTranslate();
      await this.GetAllDishes("?isGroup=1");
      this.GetAllPreferences();
    },
    dialogAboutMe: {
      handler() {
        if (this.dialogAboutMe.phone == "") this.dialogAboutMe.phone = document.querySelector("#myPhoneNumber").value;
      },
      deep: true
    }
  },
  async mounted() {
    if (!this.VALIDATION_TRANSLATE) {
      this.ValidationTranslate();
    }
    if (!this.PREFERENCES_ALL) {
      this.GetAllPreferences();
    }
    if (!this.DISHES) {
      await this.GetAllDishes("?isGroup=1");
    }
    if (!this.COMPANY) {
      this.UpdateCompany();
    }
    if (!this.LIMITS) {
      this.GetLimits();
    }
    if (!this.GET_ROLES) {
      this.Roles();
    }
    if (!this.GET_CARDS) {
      this.GetCards();
    }
    if (!this.ORDERS) {
      await this.GetOrders();
    }

    if (!this.USER.name || !this.USER.last_name) document.querySelector("body").style.overflow = "hidden";

    this.dialogAboutMe.name = this.USER.name ? this.USER.name : null;
    this.dialogAboutMe.last_name = this.USER.last_name ? this.USER.last_name : null;
    this.dialogAboutMe.email = this.USER.email ? this.USER.email : null;
    this.dialogAboutMe.phone = this.USER.phone ? this.USER.phone.replace("380", "") : null;
    this.dialogAboutMe.min_order = this.USER?.min_order ? this.USER.min_order : null;
    this.dialogAboutMe.role = null;
    this.dialogAboutMe.limit = null;
    if (this.USER.birthday) {
      let bday = this.bDayFormat(this.USER.birthday);
      this.dialogAboutMe.birthday = `${bday.dd} / ${bday.mm} / ${bday.yyyy}`;
    }

    const im = new Inputmask({ mask: "+38 (099) 999 99 99", placeholder: "" });
    im.mask(document.getElementById("myPhoneNumber"));

    const bd = new Inputmask({ mask: "99 / 99 / 9999", placeholder: "", jitMasking: true });
    bd.mask(document.getElementById("myBirthday"));

    Inputmask({
      mask: "9999999999999999",
      placeholder: ""
    }).mask(".cardNum input");

    Inputmask({
      mask: "99/99",
      placeholder: "",
      jitMasking: true
    }).mask(".cardDate input");

    Inputmask({
      mask: "9999 9999 9999 9999",
      placeholder: ""
    }).mask("#card");
  }
};
</script>

<style lang="scss">
.setting-page {
  position: relative;
  background: var(--bg-main);
  min-height: 1164px;

  .custom-select {
    width: calc(50% - 10px);

    @media (max-width: 764px) {
      width: 100%;
    }
  }

  .gray {
    color: var(--dark-40);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  .pref-checkbox {
    border: 1px solid var(--bg-main);
    background: var(--white-100);
    height: 48px;
    padding: 0 12px;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    transition: 0.1s;
    margin: 4px;
    text-transform: uppercase;
    color: var(--dark-40);
    &.active {
      color: var(--dark-100);
      background: var(--bg-main);
    }
  }
  .info-meassage {
    box-shadow: none;

    a {
      white-space: nowrap;
      text-decoration: none;
      color: var(--bg-white);
    }
  }
  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  .main.page-content {
    max-width: 1440px;
    padding-top: 82px;
    margin: 0 auto;
  }
  .title-box {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .white {
      transition: 0.2s;
      background: var(--blue-light);
      color: var(--dark-100);
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.04em;
      &:hover {
        transition: 0.2s;
        color: var(--white-100);
        background: var(--blue-main);
      }
    }
    h1 {
      font-weight: 400;
    }
  }

  .content {
    padding: 0 20px 82px;
    margin-top: 20px;
    display: flex;
  }
  .l-box {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 10px;
  }
  .r-box {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 10px;
  }
  .card {
    color: var(--dark-100);
    background: var(--white-100);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;

    & > div:not(:last-child) .review-btn-fix {
      margin-bottom: 36px;
    }
  }
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-add {
      margin: 0;
      padding: 0;
      height: auto;
      text-transform: none;
      background: none;
      border: none;

      &:hover {
        color: var(--blue-main);
      }

      svg {
        color: var(--blue-main);
      }
    }
  }
  .card-title {
    text-transform: uppercase;
  }
  .card-content {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    &.limits-list {
      margin-top: 0;
      .card-cell-25 {
        margin-top: 20px;
        &.guest {
          order: 1;
        }
      }
    }
  }
  .review-btn-fix {
    margin-top: 8px;
  }
  .card-cell-50 {
    width: 50%;
    display: flex;
    flex-direction: column;
    &:nth-child(2n) {
      padding-left: 10px;
    }
    &:nth-child(n + 3) {
      margin-top: 20px;
    }
  }
  .card-cell-25 {
    width: 25%;
    display: flex;
    flex-direction: column;
    &:nth-child(n + 5) {
      margin-top: 20px;
    }
  }
  .cell-label {
    color: var(--dark-40);
  }
  .info {
    display: flex;
    align-items: center;
  }
  .info.second {
    .info-icon {
      margin-bottom: 4px;
    }
  }
  span.blue {
    color: var(--blue-main);
  }
  .card-btn-blue.blue {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.04em;
  }
  .checkbox-box {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;
    // margin-bottom: 36px;
  }

  .btn-add {
    margin: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: var(--dark-40);
    svg {
      color: var(--dark-40);
    }
  }
  .info-company {
    display: flex;
    align-items: center;
    span {
      color: var(--dark-40);
    }
  }
  .dialog-review {
    .custom-select .selected {
      color: var(--dark-100);
    }
    .message {
      font-family: "Aeonik Pro", sans-serif;
    }
    .futer-conatainer {
      display: flex;
      justify-content: space-between;
      textarea {
        margin-top: 10px;
        margin-bottom: 7px;
        min-height: 96px;
        max-width: 325px;
        resize: none;
      }
    }
    .rating-box {
      width: 100%;
      max-width: 325px;
      padding-left: 18px;

      span {
        color: var(--dark-40);
      }
    }
    .rating {
      width: 100%;
      display: flex;
      position: relative;
      left: -6px;
    }
    .star {
      margin: 7px 4px 7px 7px;
      cursor: pointer;
      color: var(--dark-10);

      svg {
        transition: 0.4s ease;
      }

      &.hovered svg {
        fill: var(--blue-main);
      }
    }
    .star.active {
      color: var(--blue-main);
    }
    .input-container {
      margin-top: 46px;
      display: flex;
      justify-content: space-between;
    }
  }
  .dialog-edit-company {
    span.message {
      color: var(--dark-40);
    }
    .inputs-box {
      margin-top: 40px;
      display: flex;
      .main {
        margin: 0;
        &:first-child {
          margin-right: 10px;
        }
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
    .dialog-btns {
      margin-top: 36px;
    }
  }
  .dialog-edit-member {
    .main {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      min-width: 325px;

      @media (max-width: 359px) {
        min-width: auto;
      }
    }
    .input-box {
      width: 100%;
      transition: all 0.2s;
      padding-right: 16px;
      display: flex;
      align-items: flex-end;
      position: relative;
      border: 1px solid var(--dark-10);
      border-radius: 8px;
      display: flex;
      align-items: center;

      #newLimit ~ svg {
        fill: var(--dark-40);
        cursor: pointer;
      }
    }
    .input {
      width: 100%;
      padding: 14px 0 10px 15px;
      border: none;
      outline: none;
      border-radius: 8px;
      &::placeholder {
        color: var(--dark-40);
      }
      &:focus ~ svg {
        fill: var(--blue-main);
      }
    }
    .label {
      top: -11px;
      left: 11px;
      transform: none;
      position: absolute;
      color: var(--dark-40);
      padding: 0 4px;
      background: var(--white-100);
      transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      pointer-events: none;
      font-size: 12px;
    }
    .input-box:focus-within {
      border-color: var(--blue-main);
      .label {
        color: var(--blue-main);
      }
    }
  }
  .dialog-about-me,
  .dialog-edit-member {
    .inputs-box {
      margin-bottom: 32px;
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      .custom-select,
      .main {
        margin: 0;
        &:nth-child(n + 3) {
          margin-top: 36px;
        }
        &:nth-child(odd) {
          margin-right: 10px;
        }
        &:nth-child(even) {
          margin-left: 10px;
        }
      }
      .info-icon {
        margin: 0 -2px 0 5px;
      }
    }
  }
  .dialog-edit-pass {
    .inputs-box {
      display: flex;
      margin: 16px 0 36px;
      .main {
        margin: 0;
        &:nth-child(odd) {
          margin-right: 10px;
        }
        &:nth-child(even) {
          margin-left: 10px;
        }
        svg {
          color: var(--dark-40);
        }
        &:focus-within {
          svg {
            color: var(--blue-main);
          }
          .not-valid {
            svg {
              color: var(--error);
            }
          }
        }
      }
    }
  }
  .dialog-setting span.blue {
    color: var(--blue-main);
    cursor: pointer;
  }
  .language-box {
    margin-top: 20px;
    display: flex;
    input[type="radio"] {
      display: none;
    }
    .language {
      cursor: pointer;
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-left: 80px;
      }
    }
    .language-name {
      color: var(--dark-40);
      &:hover {
        color: var(--blue-main);
      }
    }
    .check-box {
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--dark-10);
      border-radius: 4px;
      height: 24px;
      width: 24px;
      svg {
        display: none;
        width: 18px;
        height: 18px;
      }
    }
    input[type="radio"]:checked ~ .check-box {
      background: var(--blue-main);
      svg {
        color: var(--white-100);
        display: flex;
      }
    }
    input[type="radio"]:checked ~ .language-name {
      color: var(--blue-main);
    }
  }
  @media (max-width: 1024px) {
    .content {
      display: flex;
      flex-direction: column;
      padding-bottom: 100px;
    }
    .r-box,
    .l-box {
      width: 100%;
      padding: 0;
    }
    .info-meassage {
      max-width: 250px;
      padding: 15px;
    }
    .info-1 .info-meassage {
      left: -211px !important;

      .bl {
        left: auto;
        right: 25px;
      }
    }
    .card-cell-25:nth-child(4n - 3) {
      .info-1 .info-meassage {
        left: -24px !important;
        .info-arrow {
          right: auto;
          left: 25px;
        }
      }
    }
  }
  @media (max-width: 764px) {
    .card-cell-25:nth-child(4n - 3),
    .card-cell-25:nth-child(2n - 1) {
      .info-1 .info-meassage {
        left: -24px !important;
        .info-arrow {
          left: 25px;
        }
      }
    }

    .card-cell-50 {
      width: 100%;
      &:not(:first-child) {
        margin-top: 20px;
      }
      &:nth-child(2n) {
        padding-left: 0;
      }
    }
    .card-cell-25 {
      width: 50%;
      &:nth-child(4n) {
        padding-left: 0;
      }

      &:nth-child(4n),
      &:nth-child(3n) {
        margin-top: 20px;
      }
    }
    .title-box {
      align-items: center;
    }
    .language-box {
      flex-direction: column;
      .language:not(:first-child) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
    .info-1 .info-meassage {
      left: -123px !important;
      .bl {
        left: auto;
        right: 112px;
      }
    }

    .dialog-review {
      .custom-select {
        margin-bottom: 38px;
      }

      .custom-select {
        @media (max-width: 765px) {
          max-width: none;
        }
      }
    }

    .content {
      padding-bottom: 170px;
    }
    .dialog-edit-pass .inputs-box,
    .dialog-about-me .inputs-box,
    .dialog-edit-member .inputs-box,
    .dialog-review .input-container {
      flex-direction: column;
    }
    .dialog-edit-member .inputs-box .main:nth-child(odd),
    .dialog-edit-member .inputs-box .main:nth-child(even) {
      margin: 0;
    }
    .dialog-edit-member .inputs-box .main:not(:first-child) {
      margin-top: 20px;
    }
    .dialog-edit-member .inputs-box .main:last-child {
      margin-bottom: 20px;
    }
    .dialog-edit-member .info-meassage {
      left: -211px !important;
      .info-arrow {
        left: auto;
        right: 25px;
      }
    }

    .dialog-review .futer-conatainer {
      flex-direction: column;
      textarea {
        margin-top: 10px;
        max-width: 100%;
        resize: none;
      }
      .rating-box {
        margin-top: 20px;
      }
    }
    .dialog-about-me .inputs-box {
      margin-top: 0;
      margin-bottom: 40px;
    }
    .dialog-edit-pass .inputs-box .main:nth-child(even),
    .dialog-edit-pass .inputs-box .main:nth-child(odd),
    .dialog-about-me .inputs-box .custom-select:nth-child(even),
    .dialog-about-me .inputs-box .custom-select:nth-child(odd),
    .dialog-about-me .inputs-box .main:nth-child(even),
    .dialog-about-me .inputs-box .main:nth-child(odd) {
      margin-right: 0;
      margin-left: 0;
      margin-top: 36px;
      margin-bottom: 0;
      max-width: auto;
      min-width: 100%;
    }
    .dialog-about-me .inputs-box .custom-select:nth-child(odd) {
      margin-top: 36px;
    }
    .dialog-edit-company .inputs-box {
      flex-direction: column;
      .main {
        &:first-child {
          margin: 0;
        }
        &:not(:first-child) {
          margin-left: 0;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
  @media (max-width: 375px) {
    .title-box {
      flex-direction: column;
      button {
        margin-top: 40px;
        align-self: flex-start;
      }
      h1 {
        align-self: flex-start;
      }
    }
    .dialog-edit-member .inputs-box .main,
    .dialog-edit-company .inputs-box .main:first-child {
      min-width: 100%;
    }
  }
  .card {
    &-label {
      margin-bottom: 4px;
      color: var(--dark-40);
      font-size: 12px;
      letter-spacing: 0.04em;
      line-height: 2;
    }
    &-info {
      display: flex;
      align-items: center;

      & > svg {
        margin-right: 6px;
      }

      & > img {
        margin: 0 2px 0 4px;
      }
    }
    &-value {
      font-size: 12px;
      letter-spacing: 0.04em;
      line-height: 2;
    }
    &-remove {
      padding: 0;
      background: none;
      border: none;
      cursor: pointer;

      svg {
        vertical-align: middle;
      }
    }
  }
  .card-info img {
    width: 22px;
  }
  .desktop-icon {
    @media (max-width: 1199px) {
      display: none;
    }
  }
  .mobile-icon {
    @media (min-width: 1200px) {
      display: none;
    }
  }
}
</style>
